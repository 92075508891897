<template>
  <div class="tenant-profile">
    <bg-breadcrumb :links="breadcrumbUrl" />
    <bg-text size="heading-3" class="tenant-profile__title">{{
      profileName
    }}</bg-text>
    <div v-if="!isLoadContent">
      <card-tenant-info :profile-data="userProfile" />
      <div class="tenant-profile__history-title">
        <bg-text size="heading-3">Riwayat Pencarian Kos</bg-text>
      </div>
      <top-action-bar
        :show-search-type="false"
        default-search-type="kost_name"
        @btn-filter-clicked="handleToggleFilterModal()"
        @action-search="handleGetData"
        data-testid="search-action-bar"
        is-profile
      />
      <div class="tenant-profile__table">
        <table class="ss-table ss-table__header">
          <thead>
            <tr>
              <th
                v-for="(header, i) in headers"
                :aria-label="header.text"
                :key="i"
              >
                <div
                  class="table-header"
                  :class="{
                    'table-header__align-left': isHeaderForDate(header.text),
                  }"
                  data-testid="button-sort-list"
                  @click="updateSortingList(isHeaderForDate(header.text))"
                >
                  <bg-text
                    class="table-header__text"
                    :class="{
                      'table-header__date': isHeaderForDate(header.text),
                    }"
                    size="title-5"
                    >{{ header.text }}</bg-text
                  >
                  <span
                    v-if="isHeaderForDate(header.text)"
                    class="table-header__icon"
                  >
                    <bg-icon :name="iconSortType" size="sm" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in trackerList"
              :key="`loading-tr-${i}`"
              class="table-body__row"
              :class="{ 'table-body__row--active': isRowActive(i + 1) }"
              data-testid="table-row-data"
              @click="
                handleClickRow({
                  id: item.id,
                  active: i + 1,
                  phase: item.phase,
                  bookingId: item.funnel_id,
                })
              "
            >
              <td>{{ item.room_type_name }}</td>
              <td>
                <bg-label-rainbow
                  :color="generateLabelType(item.phase)"
                  class="tenant-profile__label tenant-profile__capitalize"
                >
                  {{ item.phase }}
                </bg-label-rainbow>
              </td>
              <td>
                <bg-label-rainbow
                  :color="generateLabelType(item.status)"
                  class="tenant-profile__label"
                >
                  {{ item.status }}
                </bg-label-rainbow>
              </td>
              <td class="table-col__date">
                <div class="table-body__date">
                  {{ mixDateFormatterToDisplay(item.status_date, 'DD/MM/YYYY')
                  }}<br />
                  <div
                    class="table-body__date--float-right"
                    data-testid="tracker-status-date"
                  >
                    {{ mixDateFormatterToDisplay(item.status_date, 'HH:mm') }}
                  </div>
                </div>
              </td>
              <td v-on:click.stop>
                <bg-link
                  @click="handleShowNoteModal(item)"
                  class="table-body__ellipsis"
                  data-testid="tracker-note-modal"
                >
                  {{ handleNoteValue(item) }}
                </bg-link>
              </td>
              <td>{{ item.owner_name }}</td>
            </tr>
          </tbody>
        </table>
        <tracker-phase-card
          v-if="selectedRow.active"
          :content-id="selectedRow.id"
          :status="selectedRow.phase"
          data-testid="tracker-phase-card"
          @handle-show-notes-modal="handleShowNoteModal"
          @handle-show-history-notes="handleShowNoteHistoryModal"
          @handle-show-check-in-check-out="handleShowCheckinCheckoutModal"
        />
      </div>
      <create-note-modal
        :show-modal="isShowModalNote"
        :note-value="noteValue"
        :tracker-id="trackerId"
        :note-id="noteId"
        :is-edit="!!noteId"
        :tracker-status="trackerStatus"
        @update-success="getTrackerByProfile"
        @close-note-modal="handleShowNoteModal()"
      />
      <note-history-modal
        :show-modal="isShowModalHistory"
        :row-data="noteHistoryData"
        @close-note-modal="handleShowNoteHistoryModal()"
        @show-create-modal="handleShowNoteModal()"
        @note-updated="getTrackerByProfile"
      />
      <check-in-check-out-modal
        :show-modal="checkinData.openModal"
        :confirmation-type="checkinData.confirmationType"
        :booking-id="selectedRow.bookingId"
        :tracker-id="checkinData.trackerId"
        @close-modal="checkinData.openModal = false"
        @confirmation-success="handleGetData(filterParams)"
      />
      <bg-empty-state
        v-if="isEmptyResult"
        title="Data Tidak Ditemukan"
        illustration-name="login-failed"
        illustration-alt="Data tidak ditemukan"
        description="Mohon periksa kembali kata kunci yang Anda masukkan"
        data-testid="empty-state"
      />
      <bg-pagination
        v-else-if="totalItem > limitPage"
        v-model="currentPage"
        :page-total="totalPage"
        data-testid="tenant-tracker-pagination"
        @click="handleClickPagination()"
      />
    </div>
    <skeleton-loader
      v-else
      :columns="headers"
      type="profile"
      data-testid="tenant-tracker-loader"
    />
  </div>
</template>

<script>
import {
  BgText,
  BgIcon,
  BgBreadcrumb,
  BgEmptyState,
  BgPagination,
  BgLabelRainbow,
  BgLink,
} from 'bangul-vue';
import labelUtils from '@admin/mixins/labelGenerator';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';
import TrackerProfileApi from '@admin_endpoints/tenant-tracker';
import TopActionBar from '@admin/pages/TenantTracker/components/TopActionBar';
import TrackerPhaseCard from '@admin/pages/TenantTracker/components/TrackerPhaseCard';
import TableProperty from '@admin/pages/TenantTracker/mixins/trackerProfile';
import CreateNoteModal from '@admin/pages/TenantTracker/components/CreateNoteModal';
import SkeletonLoader from '@admin/pages/TenantTracker/components/SkeletonLoader';
import NoteHistoryModal from '@admin/pages/TenantTracker/components/NoteHistoryModal';
import CardTenantInfo from '@admin/pages/TenantTracker/components/CardTenantInfo';
import CheckInCheckOutModal from '@admin/pages/TenantTracker/components/CheckInCheckOutModal';

export default {
  name: 'TenantTrackerProfile',
  data() {
    return {
      limitPage: 10,
      currentPage: 1,
      noteValue: '',
      noteId: '',
      trackerList: [],
      profileName: '',
      sortType: 'desc',
      isLoadContent: false,
      isShowModalFilter: false,
      isShowModalNote: false,
      selectedRow: {
        id: 0,
        phase: '',
        active: '',
        bookingId: '',
      },
      trackerId: '',
      trackerStatus: '',
      storageParams: {},
      noteHistoryData: [],
      isShowModalHistory: false,
      checkinData: {
        openModal: false,
        confirmationType: '',
        trackerId: '',
      },
      isEmptyResult: false
    };
  },
  mixins: [TableProperty, labelUtils, mixDateFormatterToDisplay],

  components: {
    BgBreadcrumb,
    BgText,
    BgIcon,
    BgLink,
    BgEmptyState,
    BgPagination,
    TopActionBar,
    CardTenantInfo,
    BgLabelRainbow,
    SkeletonLoader,
    CreateNoteModal,
    TrackerPhaseCard,
    NoteHistoryModal,
    CheckInCheckOutModal,
  },

  computed: {
    totalPage() {
      return Math.ceil(this.totalItem / this.limitPage);
    },

    iconSortType() {
      /* istanbul ignore next */
      return this.sortType === 'desc' ? 'down' : 'up';
    },
  },
  created() {
    const paramsStorage = sessionStorage.getItem('searchParamsProfile');
    this.storageParams = paramsStorage ? JSON.parse(paramsStorage) : {};
    this.getTrackerByProfile({ page: this.currentPage });
  },

  methods: {
    handleGetData(data) {
      this.currentPage = 1;
      this.filterParams = data;
      this.getTrackerByProfile(data);
    },

    handleNoteValue(data) {
      return data.note?.message || '+ Tambah Catatan';
    },
    isHeaderForDate(headerContent) {
      return headerContent === 'Tgl Update';
    },

    handleClickRow(selectedRowData) {
      this.selectedRow = selectedRowData;
    },

    isRowActive(currentRowNumber) {
      return currentRowNumber === this.selectedRow.active;
    },

    handleToggleFilterModal() {
      this.isShowModalFilter = !this.isShowModalFilter;
    },

    handleShowCheckinCheckoutModal(checkinData) {
      this.checkinData = checkinData;
    },

    handleClickPagination() {
      this.selectedRow.active = false;
      this.getTrackerByProfile({
        ...this.filterParams,
        page: this.currentPage,
      });
    },

    handleShowNoteModal(data) {
      this.isShowModalNote = !this.isShowModalNote;
      /* istanbul ignore else */
      if (data) {
        const { cardNoteStatus, cardNote, id, note, trackerId } = data;
        this.noteValue = note?.message || cardNote?.message;
        this.trackerId = trackerId || id;
        this.noteId = note?.id || cardNote?.id;
        this.trackerStatus = data?.status || cardNoteStatus;
      }
    },

    async getTrackerByProfile(listParams) {
      const searchParams = {
        ...this.storageParams,
        ...listParams,
        limit: this.limitPage,
        userId: this.$route.params.userId,
        trackerId: this.$route.params.trackerId,
        sort_by: 'status_date',
        sort_order: this.sortType,
        page: this.currentPage,
      };
      sessionStorage.setItem(
        'searchParamsProfile',
        JSON.stringify(searchParams)
      );
      this.storageParams = {};
      this.isLoadContent = true;
      this.resetSelectedRow();
      const response = await TrackerProfileApi.getTrackerProfile(searchParams);

      if (response.data.status) {
        this.isLoadContent = false;
        this.pageInfo = response.data.pagination;
        if (response.data.trackers.length > 0) {
          this.isEmptyResult = false;
          this.trackerList = response.data.trackers;
          this.totalItem = this.pageInfo?.total_items;
          this.updateProfileInfo(response.data.user);
        } else {
          this.isEmptyResult = true;
        }
      } else {
        alert('Terjadi Galat, Silahkan coba lagi.');
      }
    },

    updateProfileInfo(profile) {
      this.profileName = profile.name;
      this.breadcrumbUrl[1].name = profile.name;
      const keyInfo = ['id', 'name', 'phone_number', 'email'];
      this.userProfile.map((user, i) => {
        user.content = profile[keyInfo[i]];
        return 0;
      });
    },

    resetSelectedRow() {
      this.trackerList = '';
      this.selectedRow = {
        id: 0,
        phase: '',
        active: '',
        notes: [],
      };
    },

    updateSortingList(isUpdateSortType) {
      /* istanbul ignore else */
      if (isUpdateSortType) {
        /* istanbul ignore next */
        const newSortType = this.sortType === 'desc' ? 'asc' : 'desc';
        this.sortType = newSortType;
        this.getTrackerByProfile();
      }
    },

    handleShowNoteHistoryModal(data) {
      this.isShowModalHistory = !this.isShowModalHistory;
      if (this.isShowModalHistory && data) {
        this.noteHistoryData = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./TenantTrackerProfile.scss"></style>
