<template>
  <div class="note-history-modal">
    <bg-modal
      v-model="isShowHistoryModal"
      :closeOnClickBackdrop="false"
      desktop-size="md"
      @close="handleCloseModal"
    >
      <bg-text size="heading-4"
        >Riwayat Catatan -
        <span class="note-history-modal__capitalize">{{
          rowData.phase
        }}</span></bg-text
      >
      <bg-text size="body-2" class="note-history-modal__subtitle">
        {{ rowData.room_type_name }}
      </bg-text>
      <bg-divider />
      <div class="note-history-modal__section">
        <div
          v-for="(item, i) in historyData"
          :key="i"
          class="note-history-modal__list"
        >
          <div>
            <bg-text size="body-2" class="note-history-modal__capitalize">{{
              item.status
            }}</bg-text>
            <bg-link
              variant="low"
              @click="handleToggleCreateNoteModal(item.note, item.status)"
            >
              {{ handleNoteValue(item.note) }}
            </bg-link>
          </div>
          <bg-text size="body-4" class="note-history-modal__list-date">
            {{ mixDateFormatterToDisplay(item.date) }}<br />
            {{ mixDateFormatterToDisplay(item.date, 'HH:mm') }}
          </bg-text>
        </div>
      </div>
    </bg-modal>
    <create-note-modal
      :show-modal="isShowCreateModal"
      :note-value="noteValue"
      :tracker-id="rowData.id"
      :tracker-status="noteStatus"
      :is-edit="isEdit"
      :note-id="noteId"
      @close-note-modal="handleToggleCreateNoteModal()"
      @update-success="handleUpdateSuccess()"
    />
  </div>
</template>

<script>
import { BgModal, BgText, BgLink, BgDivider } from 'bangul-vue';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';
import CreateNoteModal from '@admin/pages/TenantTracker/components/CreateNoteModal';

export default {
  name: 'NoteHistoryModal',
  data() {
    return {
      value: this.noteValue,
      isShowHistoryModal: false,
      isShowCreateModal: false,
      noteValue: '',
      noteStatus: '',
      isEdit: false,
      noteId: '',
    };
  },

  mixins: [mixDateFormatterToDisplay],

  components: {
    BgText,
    BgModal,
    BgLink,
    BgDivider,
    CreateNoteModal,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    rowData: {
      type: [Array, Object],
      default: () => {},
    },
  },

  watch: {
    showModal(isOpen) {
      this.isShowHistoryModal = isOpen;
    },
  },

  computed: {
    historyData() {
      return this.rowData.notes;
    },
  },

  methods: {
    handleNoteValue(data) {
      return data?.message || '+ Tambah Catatan';
    },

    handleExistingNote(data) {
      this.noteId = data?.id;
      this.isEdit = true;
    },

    handleCloseModal() {
      this.isShowHistoryModal = false;
      this.$emit('close-note-modal');
    },

    handleToggleCreateNoteModal(notes, noteStatus) {
      this.noteId = notes?.id;
      this.isEdit = !!this.noteId;
      this.noteStatus = noteStatus;
      this.noteValue = notes?.message;
      this.isShowCreateModal = !this.isShowCreateModal;
      this.isShowHistoryModal = !this.isShowHistoryModal;
    },

    handleUpdateSuccess() {
      this.$emit('note-updated');
    },
  },
};
</script>
<style lang="scss" scoped src="./NoteHistoryModal.scss"></style>
