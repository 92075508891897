<template>
  <div class="tenant-chat-phase">
    <div
      v-for="(chat, i) in chatContent.chats"
      :key="i"
      class="tenant-chat-phase__list"
    >
      <div class="tenant-chat-phase__left">
        <bg-text size="body-2">{{ chat.status }}</bg-text>
        <bg-link
          variant="low"
          data-testid="button-create-note"
          @click="handleToggleCreateNoteModal(chat.note, chat.status)"
        >
          {{ handleNoteValidation(chat.note) }}
        </bg-link>
      </div>
      <bg-text size="body-4" class="tenant-chat-phase__list-date">
        {{ handleDayFormat(chat.date) }}<br />
        {{ handleDateTimeFormat(chat.date) }}
      </bg-text>
    </div>
  </div>
</template>

<script>
import { BgLink, BgText } from 'bangul-vue';
import { dayFormatterTodayYesterday, dateFormatterToDisplay } from 'Utils/formatter';

export default {
  name: 'TenantChatPhase',
  data() {
    return {
      isShowNoteModal: false,
      value: this.noteValue,
      noteValue: '',
    };
  },

  components: {
    BgLink,
    BgText,
  },

  props: {
    chatContent: {
      type: Object,
      require: true,
    },
  },
  methods: {
    handleToggleCreateNoteModal(notes, noteStatus) {
      this.$emit('handle-show-notes-modal', { notes, noteStatus });
    },
    handleDayFormat(date) {
      return dayFormatterTodayYesterday(date);
    },
    handleDateTimeFormat(date) {
      return dateFormatterToDisplay(date, 'HH:mm');
    },
    handleNoteValidation(note) {
      return note?.message || '+ Tambah Catatan';
    },
  },
};
</script>

<style lang="scss" scoped src="./TenantChatPhase.scss"></style>
