<template>
  <div class="booking-phase">
    <div class="booking-phase__container">
      <bg-text size="title-5">Tanggal Check-in</bg-text>
      <bg-text size="body-2">{{
        mixDateFormatterToDisplay(bookingInvoice.checkin_date)
      }}</bg-text>
    </div>
    <div class="booking-phase__container">
      <bg-text size="title-5">Tanggal Check-out</bg-text>
      <bg-text size="body-2">{{
        mixDateFormatterToDisplay(bookingInvoice.checkout_date)
      }}</bg-text>
    </div>
    <div class="booking-phase__container">
      <bg-text size="title-5">Hitungan / Durasi</bg-text>
      <bg-text size="body-2">{{
        generateDurationContract(bookingInvoice)
      }}</bg-text>
    </div>
    <div class="booking-phase__divider">
      <bg-divider dashed />
    </div>
    <div class="booking-phase__container">
      <bg-text size="title-5">Jumlah penyewa</bg-text>
      <bg-text size="body-2"> {{ totalTenant }}</bg-text>
    </div>
    <div class="booking-phase__container">
      <bg-text size="title-5">Nomor Kamar</bg-text>
      <bg-text size="body-2">{{ roomNumber }}</bg-text>
    </div>

    <div v-for="(invoice, index) in bookingInvoice.invoices" :key="index">
      <div class="booking-phase__spacer" />
      <div class="booking-phase__container">
        <div class="booking-phase__title">
          <bg-text size="title-5">Invoice type</bg-text>
        </div>
        <div class="booking-phase__wrapper">
          <bg-text size="body-2"
            >{{ generateInvoiceType(invoice.type) }} -
          </bg-text>
          <bg-text
            size="body-2"
            class="booking-phase__title--small-space-left"
            :class="{
              'booking-phase__title--red': isDbtStatus(invoice.payment_status),
            }"
          >
            {{ invoice.payment_status }}</bg-text
          >
        </div>
        <div class="booking-phase__title booking-phase__title--space-top">
          <bg-text size="title-5">Jatuh tempo pembayaran</bg-text>
        </div>
        <div class="booking-phase__wrapper">
          <bg-text size="body-2"
            >{{ mixDateFormatterToDisplay(invoice.due_date) }}.
          </bg-text>
          <bg-text size="body-2" class="booking-phase__title--space-left">{{
            mixDateFormatterToDisplay(invoice.due_date, 'HH:mm')
          }}</bg-text>
        </div>
        <div class="booking-phase__wrapper">
          <bg-link
            variant="medium"
            class="booking-phase__link"
            data-testId="button-open-invoice"
            @click="redirectToInvoice(invoice.url)"
            >Link Invoice</bg-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BgText, BgDivider, BgLink } from 'bangul-vue';
import mixDateFormatterToDisplay from '@admin/mixins/formatter';
import tenantBookingPhase from '../../mixins/tenantBookingPhase';

export default {
  name: 'TenantBookingPhase',

  data() {
    return {
      accordionActiveIds: [],
    };
  },

  props: {
    bookingInvoice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    totalTenant() {
      return `${this.bookingInvoice.guest_total} Penyewa, ${this.tenantInfo}`;
    },

    roomNumber() {
      return this.bookingInvoice?.room_number || '-';
    },

    tenantInfo() {
      return this.generateInfoTenantFamily(this.bookingInvoice);
    },
  },

  components: {
    BgText,
    BgLink,
    BgDivider,
  },

  mixins: [mixDateFormatterToDisplay, tenantBookingPhase],

  methods: {
    isDbtStatus(paymentStatus) {
      return paymentStatus === 'Belum Dibayar';
    },

    generateInvoiceType(invoice) {
      const invoiceType = {
        full_payment: 'Full Payment',
        down_payment: 'DP',
        dp_settlement: 'DP',
        settlement: 'Sisa Pelunasan',
      };

      return invoiceType[invoice];
    },

    redirectToInvoice(url) {
      window.open(url);
      window.opener = null;
    },
  },
};
</script>

<style lang="scss" scoped src="./TenantBookingPhase.scss"></style>
