<template>
  <bg-card variant="line" class="phase-card fadeInRight">
    <div v-if="!isLoadData && detailPhase" class="phase-card__scroll-view">
      <div class="phase-card__label-wrapper">
        <bg-text size="title-4" class="phase-card__title">{{ status }}</bg-text>
        <bg-text v-if="isCheckInAndBooking" size="body-4"
          >#{{ detailPhase.booking_code }}</bg-text
        >
      </div>
      <div v-if="!isChatPhase" class="phase-card__status-section">
        <bg-label-rainbow
          :color="generateLabelType(detailPhase.status)"
          class="phase-card__label"
          >{{ detailPhase.status }}</bg-label-rainbow
        >
        <bg-button
          variant="tertiary-naked"
          class="phase-card__history-link"
          @click="handleShowNoteHistoryModal(detailPhase)"
        >
          Lihat Catatan
        </bg-button>
      </div>
      <bg-text
        v-if="isNeedConfirmation"
        size="label-1"
        class="phase-card__label-warning"
        :class="{ 'phase-card__label-warning--gray': false }"
        >{{ confirmationText }}</bg-text
      >
      <div class="phase-card__room">
        <div class="phase-card__room-wrapper">
          <bg-text size="title-5" class="phase-card__room-title">{{
            detailPhase.room_type_name
          }}</bg-text>
          <bg-image
            v-if="detailPhase.room_type_images"
            :src="detailPhase.room_type_images.small"
            ratio="4:1"
            class="phase-card__room-image"
          />
        </div>
        <bg-text size="body-2" class="phase-card__room-subtitle"
          >{{ propertyInfo }}
        </bg-text>
        <!-- Property Information -->
        <!-- Button confirmation Checkin -->
        <bg-button
          v-if="isEnableCheckinCheckoutAction(detailPhase.status)"
          block
          size="md"
          class="mt-16 mb-18"
          variant="secondary"
          :loading="false"
          data-testid="confirmation-checkinout"
          @click="handleConfirmationCheckinOrCheckout(true)"
        >
          Konfirmasi {{ confirmationType }}
        </bg-button>
        <!-- Button confirmation Checkin -->
        <div v-if="isSurveyPhase" class="phase-card__link">
          <bg-link
            @click="handleOpenMaps"
            variant="medium"
            data-testid="button-open-map-location"
            >{{ roomAddress }}</bg-link
          >
        </div>
      </div>
      <bg-divider dashed class="phase-card__divider" />

      <tenant-survey-phase
        v-if="status === 'survei'"
        :content-survey-phase="detailPhase"
      />
      <tenant-booking-phase
        v-else-if="isCheckInAndBooking"
        :booking-invoice="detailPhase"
      />
      <tenant-chat-phase
        v-else-if="status === 'chat'"
        :chat-content="detailPhase"
        @handle-show-notes-modal="handleShowNoteModal"
      />
    </div>
    <skeleton-loader
      v-else
      type="card"
      data-testid="tracker-phase-card-loader"
    />
  </bg-card>
</template>

<script>
import {
  BgButton,
  BgCard,
  BgText,
  BgLabelRainbow,
  BgImage,
  BgDivider,
  BgLink,
} from 'bangul-vue';
import { mapGetters } from 'vuex';
import TenantSurveyPhase from '@admin/pages/TenantTracker/components/TenantSurveyPhase';
import TenantBookingPhase from '@admin/pages/TenantTracker/components/TenantBookingPhase';
import TenantChatPhase from '@admin/pages/TenantTracker/components/TenantChatPhase';
import SkeletonLoader from '@admin/pages/TenantTracker/components/SkeletonLoader';
import TenantCommunicationApi from '@admin/api/endpoints/tenant-tracker';
import labelUtils from '@admin/mixins/labelGenerator';
import formatter from '@/_admin/mixins/formatter';

export default {
  name: 'TrackerPhaseCard',

  data() {
    return {
      detailPhase: '',
      isLoadData: false,
      isShowModalNote: false,
      isShowModalHistory: false,
      checkinCheckoutStatus: '',
    };
  },

  props: {
    contentId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      default: '',
    },
  },

  mixins: [labelUtils, formatter],

  components: {
    BgButton,
    BgCard,
    BgLink,
    BgText,
    BgImage,
    BgDivider,
    SkeletonLoader,
    BgLabelRainbow,
    TenantBookingPhase,
    TenantSurveyPhase,
    TenantChatPhase,
  },

  computed: {
    ...mapGetters(['xCheckUserPermission']),
    hasAccessCheckin() {
      return this.xCheckUserPermission('tenant-tracker-checkin');
    },

    hasAccessCheckout() {
      return this.xCheckUserPermission('tenant-tracker-checkout');
    },

    confirmationText() {
      const checkoutDate = this.mixDateFormatterToDisplay(
        this.detailPhase.checkout_date,
        'DD MMMM YYYY'
      );
      const checkoutDay = this.mixDateFormatterToDisplay(
        this.detailPhase.checkout_date,
        'dddd'
      );

      return `Konfirmasi sebelum ${checkoutDay}, ${checkoutDate}`;
    },

    roomAddress() {
      return this.detailPhase?.room_type_address;
    },

    confirmationType() {
      if (!this.detailPhase?.status) return 'check-in';
      const indexOfPhase = 1;
      const bookingPhase = this.detailPhase.status.split('-');

      return bookingPhase[indexOfPhase] === 'in' ? 'check-in' : 'check-out';
    },

    isChatPhase() {
      return this.detailPhase?.phase === 'chat';
    },

    isSurveyPhase() {
      return this.detailPhase?.phase === 'survei';
    },

    isCheckInAndBooking() {
      return ['checkin', 'checkout', 'booking'].includes(this.status);
    },

    propertyInfo() {
      return `Tipe ${this.detailPhase?.room_unit_type || '-'}・${
        this.genderType
      }・Sisa ${this.detailPhase.total_room_available} kamar`;
    },

    genderType() {
      const genderList = ['Campur', 'Putra', 'Putri'];
      return genderList[this.detailPhase.room_type_gender];
    },

    isNeedConfirmation() {
      const confirmationStatus = [
        'hari h check-out',
        'belum check-out',
        'penyewa ajukan berhenti sewa',
        'h-1 check-out'
      ];

      return (
        confirmationStatus.indexOf(this.detailPhase.status.toLowerCase()) >= 0
      );
    },
  },

  watch: {
    contentId: {
      immediate: true,
      handler(newSelectedId) {
        /* istanbul ignore else */
        if (newSelectedId) {
          this.getPhaseDetail();
        }
      },
    },
  },

  methods: {
    handleShowNoteModal({ notes, noteStatus }) {
      this.$emit('handle-show-notes-modal', {
        cardNote: notes,
        cardNoteStatus: noteStatus,
        isModalOpen: true,
        trackerId: this.detailPhase.id,
      });
    },

    handleConfirmationCheckinOrCheckout(isOpenModal) {
      this.$emit('handle-show-check-in-check-out', {
        openModal: isOpenModal,
        confirmationType: this.confirmationType,
        trackerId: this.contentId,
      });
    },

    isEnableCheckinCheckoutAction(checkinStatus) {
      this.checkinCheckoutStatus = checkinStatus;
      const enableStatus = ['hari h check-in', 'belum check-in', 'hari h check-out', 'belum check-out', 'penyewa ajukan berhenti sewa', 'h-1 check-out'];

      const enableCheckin = this.hasAccessCheckin &&
        enableStatus.indexOf(checkinStatus.toLowerCase()) >= 0;
      const enableCheckout = this.hasAccessCheckout &&
        enableStatus.indexOf(checkinStatus.toLowerCase()) >= 0;

      const isEnableAction = enableCheckin || enableCheckout;

      return isEnableAction;
    },

    async getPhaseDetail() {
      try {
        this.isLoadData = true;
        this.detailPhase = '';
        const res = await TenantCommunicationApi.getPhaseDetail({
          trackerId: this.contentId,
          phase: this.status,
        });

        if (res.data?.tracker) {
          this.detailPhase = res.data.tracker;
        } else {
          alert(res.data.message);
        }
      } catch (error) {
        alert('Terjadi Galat, Silahkan coba lagi.');
        console.error(error.message);
      } finally {
        this.isLoadData = false;
      }
    },

    handleOpenMaps() {
      const latitude = this.detailPhase.room_type_location?.lat;
      const longitude = this.detailPhase.room_type_location?.long;
      /* istanbul ignore else */
      if (latitude && longitude) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        );
        window.opener = null;
      }
    },

    handleShowNoteHistoryModal(data) {
      this.$emit('handle-show-history-notes', data);
    },
  },
};
</script>
<style lang="scss" scoped src="./TrackerPhaseCard.scss"></style>
