export default {
  data() {
    return {
      headers: [
        {
          text: 'Nama Properti',
        },
        {
          text: 'Fase',
        },
        {
          text: 'Status',
        },
        {
          text: 'Tgl Update',
        },
        {
          text: 'Catatan',
        },
        {
          text: 'BSE',
        },
      ],

      breadcrumbUrl: [
        {
          name: 'Tenant Communication List',
          url: '/tenant-tracker',
        },
        {
          name: 'Loading...',
          url: '/#',
        },
      ],

      userProfile: [
        { label: 'User ID', content: '-' },
        { label: 'Nama Penyewa', content: '-' },
        { label: 'No. HP', content: '-' },
        { label: 'Email', content: '-' },
      ],
    };
  },

  methods: {
    isHeaderForDate(headerContent) {
      return headerContent === 'Tanggal Status';
    },
  },
};
